<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Personal Information
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Update your personal informaiton</span
        >
      </div>
      <div class="card-toolbar">
        <template >
          <button
            type="reset"
            :disabled="!firstNameValidation || !emailValidation || !phoneValidation || !companyNameValidation || !lastNameValidation"
            class="btn btn-success mr-2"
            @click="save"
          >
            Save Changes
          </button>
          <button type="reset" class="btn btn-secondary" @click="cancel">
            Cancel
          </button>
        </template>       
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Customer Info</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Avatar</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div
                class="image-input-wrapper"
                :style="{ backgroundImage: `url(${photo})` }"
              ></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  @change="onFileChange($event)"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel"
                data-toggle="tooltip"
                title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="remove"
                data-toggle="tooltip"
                title="Remove avatar"
                @click="current_photo = null"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <span class="form-text text-muted"
              >Allowed file types: png, jpg, jpeg.</span
            >
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >First Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="Name"
              :state="firstNameValidation"
              aria-describedby="first-name-feedback"
              v-model="userData.name"
              style="text-transform: capitalize"
            />
            <b-form-invalid-feedback id="first-name-feedback">
              Enter your name
            </b-form-invalid-feedback>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Last Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              ref="surname"
              class="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="Last Name"
              :state="lastNameValidation"
              aria-describedby="last-name-feedback"
              v-model="userData.last_name"
              style="text-transform: capitalize"
            />
            <b-form-invalid-feedback id="last-name-feedback">
              Enter your last name
            </b-form-invalid-feedback>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Company Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              ref="company_name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="Company Name"
              :state="companyNameValidation"
              aria-describedby="company-name-feedback"
              v-model="userData.company"
              style="text-transform: capitalize"
            />
            <b-form-invalid-feedback id="company-name-feedback">
              Enter your company name
            </b-form-invalid-feedback>
            <span class="form-text text-muted"
              >If you want your invoices addressed to a company. Leave blank to
              use your full name.</span
            >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Telegram ID</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="Telegram ID"
              v-model="userData.user_id"
              readonly
            />
            <span class="form-text text-muted"
              >Open up your telegram App, search for Suitch_bot, then follow the instructions to link your account using this information</span
            >
          </div>
        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Contact Phone</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <b-input
                ref="phone"
                type="tel"
                maxlength="12"
                class="form-control form-control-lg form-control-solid"
                placeholder="Phone"
                :state="phoneValidation"
                aria-describedby="phone-feedback"
                v-model="userData.phone"
              />
              <b-form-invalid-feedback id="phone-feedback" class="text-center">
                Enter your phone number
              </b-form-invalid-feedback>
            </div>
            <span class="form-text text-muted"
              >We'll never share your email with anyone else.</span
            >
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Email Address</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <b-input
                ref="email"
                type="email"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                :state="emailValidation"
                aria-describedby="email-feedback"
                v-model="userData.email"
              />
              <b-form-invalid-feedback id="email-feedback" class="text-center">
                Please enter a valid email
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Account</h5>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Language</label>
          <div class="col-lg-9 col-xl-6">
            <select
              class="form-control form-control-lg form-control-solid"
              ref="language"
              v-model="userData.lang"
            >
              <option value="" disabled>Please select a language option</option>
                <b-form-select-option value="en">English</b-form-select-option>
                <b-form-select-option value="es">Español - Spanish</b-form-select-option>
                <b-form-select-option value="fr">Français - French</b-form-select-option>
            </select>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Time Zone</label>
          <div class="col-lg-9 col-xl-6">
            <select
              class="form-control form-control-lg form-control-solid"
              ref="time_zone"
            >
              <template v-for="(item, i) in time_zones">
                <option
                  v-bind:key="i"
                  :value="item"
                  :selected="
                    userData.time_zone == item ? true : false
                  "
                  >{{ item }}</option
                >
              </template>
            </select>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import JwtService from "@/core/services/jwt.service";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/auth.module";
import { FETCH_USER_DETAILS } from "@/core/services/store/auth.module";
import { SOURCES } from "@/core/enums/sources.enum";

export default {
  name: "PersonalInformation",
  components: {

  },
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null,
      userData:{},
      userDataBackup: {},
      time_zones: [
        "(GMT-11:00) International Date Line West",
        "(GMT-11:00) Midway Island",
        "(GMT-11:00) Samoa",
        "(GMT-10:00) Hawaii",
        "(GMT-08:00) Alaska",
        "(GMT-07:00) Pacific Time (US & Canada)",
        "(GMT-07:00) Tijuana",
        "(GMT-07:00) Arizona",
        "(GMT-06:00) Mountain Time (US & Canada)",
        "(GMT-06:00) Chihuahua",
        "(GMT-06:00) Mazatlan",
        "(GMT-06:00) Saskatchewan",
        "(GMT-06:00) Central America",
        "(GMT-05:00) Central Time (US & Canada)",
        "(GMT-05:00) Guadalajara",
        "(GMT-05:00) Mexico City",
        "(GMT-05:00) Monterrey",
        "(GMT-05:00) Bogota",
        "(GMT-05:00) Lima",
        "(GMT-05:00) Quito",
        "(GMT-04:00) Eastern Time (US & Canada)",
        "(GMT-04:00) Indiana (East)",
        "(GMT-04:00) Caracas",
        "(GMT-04:00) La Paz",
        "(GMT-04:00) Georgetown",
        "(GMT-03:00) Atlantic Time (Canada)",
        "(GMT-03:00) Santiago",
        "(GMT-03:00) Brasilia",
        "(GMT-03:00) Buenos Aires",
        "(GMT-02:30) Newfoundland",
        "(GMT-02:00) Greenland",
        "(GMT-02:00) Mid-Atlantic",
        "(GMT-01:00) Cape Verde Is.",
        "(GMT) Azores",
        "(GMT) Monrovia",
        "(GMT) UTC",
        "(GMT+01:00) Dublin",
        "(GMT+01:00) Edinburgh",
        "(GMT+01:00) Lisbon",
        "(GMT+01:00) London",
        "(GMT+01:00) Casablanca",
        "(GMT+01:00) West Central Africa",
        "(GMT+02:00) Belgrade",
        "(GMT+02:00) Bratislava",
        "(GMT+02:00) Budapest",
        "(GMT+02:00) Ljubljana",
        "(GMT+02:00) Prague",
        "(GMT+02:00) Sarajevo",
        "(GMT+02:00) Skopje",
        "(GMT+02:00) Warsaw",
        "(GMT+02:00) Zagreb",
        "(GMT+02:00) Brussels",
        "(GMT+02:00) Copenhagen",
        "(GMT+02:00) Madrid",
        "(GMT+02:00) Paris",
        "(GMT+02:00) Amsterdam",
        "(GMT+02:00) Berlin",
        "(GMT+02:00) Bern",
        "(GMT+02:00) Rome",
        "(GMT+02:00) Stockholm",
        "(GMT+02:00) Vienna",
        "(GMT+02:00) Cairo",
        "(GMT+02:00) Harare",
        "(GMT+02:00) Pretoria",
        "(GMT+03:00) Bucharest",
        "(GMT+03:00) Helsinki",
        "(GMT+03:00) Kiev",
        "(GMT+03:00) Kyiv",
        "(GMT+03:00) Riga",
        "(GMT+03:00) Sofia",
        "(GMT+03:00) Tallinn",
        "(GMT+03:00) Vilnius",
        "(GMT+03:00) Athens",
        "(GMT+03:00) Istanbul",
        "(GMT+03:00) Minsk",
        "(GMT+03:00) Jerusalem",
        "(GMT+03:00) Moscow",
        "(GMT+03:00) St. Petersburg",
        "(GMT+03:00) Volgograd",
        "(GMT+03:00) Kuwait",
        "(GMT+03:00) Riyadh",
        "(GMT+03:00) Nairobi",
        "(GMT+03:00) Baghdad",
        "(GMT+04:00) Abu Dhabi",
        "(GMT+04:00) Muscat",
        "(GMT+04:00) Baku",
        "(GMT+04:00) Tbilisi",
        "(GMT+04:00) Yerevan",
        "(GMT+04:30) Tehran",
        "(GMT+04:30) Kabul",
        "(GMT+05:00) Ekaterinburg",
        "(GMT+05:00) Islamabad",
        "(GMT+05:00) Karachi",
        "(GMT+05:00) Tashkent",
        "(GMT+05:30) Chennai",
        "(GMT+05:30) Kolkata",
        "(GMT+05:30) Mumbai",
        "(GMT+05:30) New Delhi",
        "(GMT+05:30) Sri Jayawardenepura",
        "(GMT+05:45) Kathmandu",
        "(GMT+06:00) Astana",
        "(GMT+06:00) Dhaka",
        "(GMT+06:00) Almaty",
        "(GMT+06:00) Urumqi",
        "(GMT+06:30) Rangoon",
        "(GMT+07:00) Novosibirsk",
        "(GMT+07:00) Bangkok",
        "(GMT+07:00) Hanoi",
        "(GMT+07:00) Jakarta",
        "(GMT+07:00) Krasnoyarsk",
        "(GMT+08:00) Beijing",
        "(GMT+08:00) Chongqing",
        "(GMT+08:00) Hong Kong",
        "(GMT+08:00) Kuala Lumpur",
        "(GMT+08:00) Singapore",
        "(GMT+08:00) Taipei",
        "(GMT+08:00) Perth",
        "(GMT+08:00) Irkutsk",
        "(GMT+08:00) Ulaan Bataar",
        "(GMT+09:00) Seoul",
        "(GMT+09:00) Osaka",
        "(GMT+09:00) Sapporo",
        "(GMT+09:00) Tokyo",
        "(GMT+09:00) Yakutsk",
        "(GMT+09:30) Darwin",
        "(GMT+09:30) Adelaide",
        "(GMT+10:00) Canberra",
        "(GMT+10:00) Melbourne",
        "(GMT+10:00) Sydney",
        "(GMT+10:00) Brisbane",
        "(GMT+10:00) Hobart",
        "(GMT+10:00) Vladivostok",
        "(GMT+10:00) Guam",
        "(GMT+10:00) Port Moresby",
        "(GMT+10:00) Solomon Is.",
        "(GMT+11:00) Magadan",
        "(GMT+11:00) New Caledonia",
        "(GMT+12:00) Fiji",
        "(GMT+12:00) Kamchatka",
        "(GMT+12:00) Marshall Is.",
        "(GMT+12:00) Auckland",
        "(GMT+12:00) Wellington",
        "(GMT+13:00) Nuku'alofa"
      ],
    };
  },
  mounted() {
    this.userData=this.$store.getters.currentUser;
    this.userDataBackup=JSON.parse(JSON.stringify(this.$store.getters.currentUser));
  },
  methods: {
    save(e) {
      e.preventDefault();
      const current_token = this.userData.token;
      this.$store
        .dispatch(UPDATE_PERSONAL_INFO, {current_token,
        source: SOURCES.WEB,
          
            avatar: this.userData.avatar,
            email: this.userData.email,
            lang: this.userData.lang,
            last_name: this.userData.last_name,
            name: this.userData.name,
            phone:this.userData.phone,
            company:this.userData.company

        });
      },
    cancel() {
      this.userData.name = this.userDataBackup.name;
      this.userData.last_name = this.userDataBackup.last_name;
      this.$refs.company_name.value = this.userDataBackup.company_name;
      this.userData.phone = this.userDataBackup.phone;
      this.userData.email = this.userDataBackup.email;
      this.userData.lang = this.userDataBackup.lang;
    },
    onFileChange(e) {
  console.log(e.target.files[0]);
  const file = e.target.files[0];
  const form = new FormData();
  
  // Configuration for Axios
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: function (event) {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        console.log(progress);
      }
    }.bind(this),
  };

  // Append file and other form data
  form.append("utf8", "✓");
  form.append("authenticity_token", JwtService.getToken());
  form.append("file", file, file.name); // Corrected to "profile_avatar"

  // Optional: Show the selected image in the frontend
  if (typeof FileReader === "function") {
    const reader = new FileReader();
    reader.onload = (event) => {
      this.current_photo = event.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    alert("Sorry, FileReader API not supported");
  }

  // Perform the actual upload (example using Axios)
  this.$http
    .post("/auth/v2/user/avatar.json", form, config) // Replace "/upload_url" with your endpoint
    .then((response) => {
      console.log("File uploaded successfully", response.data);
      this.$store.dispatch(FETCH_USER_DETAILS);
    })
    .catch((error) => {
      console.error("Error uploading file", error);
    });
},

  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    // photo() {
    //   return this.current_photo == null
    //     ? this.default_photo
    //     : this.current_photo;
    // }
    photo() {
      if (this.userData.avatar != null && this.current_photo == null) {
        return "https://www.suitch.network/avatar/" + this.userData.avatar;
      }
      if (this.current_photo != null) {
        return this.current_photo;
      }
      return process.env.BASE_URL + "media/users/blank.png";
    },
    
    firstNameValidation() {
      if (this.userData == null || this.userData.name==null) {
        return false;
      }
      return this.userData.name.length > 3 ? true : false;
    },
    lastNameValidation() {
      if (this.userData == null || this.userData.last_name==null) {
        return false;
      }
      return this.userData.last_name.length > 4 ? true : false; 
    },
    companyNameValidation() {
      if (this.userData == null || this.userData.company == null) {
        return false;
      }
      return this.userData.company.length > 5 ? true : false;   
    },
    phoneValidation() {
      if (this.userData == null || this.userData.phone==null) {
        return false;
      }
      return this.userData.phone.length > 7  ? true : false; 
    },
    emailValidation() {
      if (this.userData == null || this.userData.email==null) {
        return false;
      }
      return this.userData.email.length > 8 ? true : false;   
    },
  }
};
</script>
