<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Account Information
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Change your account settings</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Account:</h5>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Username</label>
          <div class="col-lg-9 col-xl-6">
            <div class="spinner spinner-sm spinner-success spinner-right">
              <input
                class="form-control form-control-lg form-control-solid"
                type="text"
                ref="username"
                :value="currentUserAccountInfo.username"
              />
            </div>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Email Address</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg form-control-solid"
                ref="email"
                :value="currentUserAccountInfo.email"
                placeholder="Email"
              />
            </div>
            <span class="form-text text-muted"
              >Email will not be publicly displayed.
              <a href="#" class="kt-link">Learn more</a>.</span
            >
          </div>
        </div>

        <!--begin::Form Group-->
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">Communication</label>
          <div class="col-lg-9 col-xl-6">
            <div class="checkbox-inline">
              <label class="checkbox">
                <input
                  type="checkbox"
                  ref="email_com"
                  :checked="currentUserAccountInfo.communication.email"
                /><span></span> Email</label
              >
              <label class="checkbox">
                <input
                  type="checkbox"
                  ref="sms_com"
                  :checked="currentUserAccountInfo.communication.sms"
                /><span></span> SMS</label
              >
              <label class="checkbox">
                <input
                  type="checkbox"
                  ref="phone_com"
                  :checked="currentUserAccountInfo.communication.phone"
                /><span></span> Phone</label
              >
            </div>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="separator separator-dashed my-5"></div>
        <!--begin::Form Group-->
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Security:</h5>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label"
            >Login verification</label
          >
          <div class="col-lg-9 col-xl-6">
            <button
              type="button"
              class="btn btn-light-primary font-weight-bold btn-sm"
            >
              Setup login verification
            </button>
            <p class="form-text text-muted pt-2">
              After you log in, you will be asked for additional information to
              confirm your identity and protect your account from being
              compromised. <a href="#" class="font-weight-bold">Learn more</a>.
            </p>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label"
            >Password reset verification</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="checkbox-single">
              <label class="checkbox m-0">
                <input
                  type="checkbox"
                  ref="verification"
                  :checked="currentUserAccountInfo.verification"
                /><span></span> Require personal information to reset your
                password.</label
              >
            </div>
            <p class="form-text text-muted py-2">
              For extra security, this requires you to confirm your email or
              phone number when you reset your password.
              <a href="#" class="font-weight-boldk">Learn more</a>.
            </p>
            <button
              type="button"
              class="btn btn-light-danger font-weight-bold btn-sm"
            >
              Deactivate your account ?
            </button>
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_ACCOUNT_INFO } from "@/core/services/store/profile.module";

export default {
  name: "AccountInformation",
  data() {
    return {
    };
  },
  methods: {
    save() {
      var username = this.$refs.username.value;
      var email = this.$refs.email.value;
      var communication = {
        email: this.$refs.email_com.checked,
        sms: this.$refs.sms_com.checked,
        phone: this.$refs.phone_com.checked
      };
      var verification = this.$refs.verification.checked;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_ACCOUNT_INFO, {
          username,
          email,
          communication,
          verification
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      this.$refs.username.value = this.currentUserAccountInfo.username;
      this.$refs.email.value = this.currentUserAccountInfo.email;
      this.$refs.email_com.checked = this.currentUserAccountInfo.communication.email;
      this.$refs.sms_com.checked = this.currentUserAccountInfo.communication.sms;
      this.$refs.phone_com.checked = this.currentUserAccountInfo.communication.phone;
      this.$refs.verification.checked = this.currentUserAccountInfo.verification;
    }
  },
  computed: {
    ...mapGetters(["currentUserAccountInfo"])
  }
};
</script>
